body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
}

.App {
  text-align: center;
}

.Contenido {
  background-color: rgb(4, 4, 14);
  min-height: 99.9vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Contenido .c {
  display: flex;
  align-items: center;
  padding-top: 100px;
}

.Contenido .texto {
  text-align: left;
}
.Contenido h1 {
  color: #9ba7be;
  margin: 0;
}
.Contenido h3 {
  color: #7281a5;
  font-size: 25px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
}

.Contenido a {
  text-decoration: none;
  color: #fff;
  background-color: #535d74;
  padding: 5px 15px;
  border-radius: 10px;
}

.Contenido img {
  padding: 50px;
  padding-right: 100px;
  height: 250px;
}

.Barra {
  background-color: rgba(4, 4, 14, 0.745);
  backdrop-filter: blur(8px);
  top: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  border-radius: 20px;
  width: 800px;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
}
.Barra button {
  transition: all 0.5s;
  background-color: transparent;
  color: #d1d4d6;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
  font-size: 16px;
  border: 0;
}
.Barra button:hover {
  transition: all 0.5s;
  color: rgb(255, 255, 255);
  transform: translateY(-4px);
}
.Barra img {
  height: 50px;
  padding: 20px;
}



.SobreNosotros {
  background-color: rgb(4, 4, 14);
  min-height: auto;
  text-align: center;
  padding: 160px;
  font-size: calc(10px + 2vmin);
  color: white;
}




.SobreNosotros .content {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}


.SobreNosotros .perfil {
  display: flex;
  align-items: center;
}

.SobreNosotros .texto {
  text-align: left;
}

.SobreNosotros .perfil img {
  height: 500px;
}







@media only screen and (max-width: 768px) {


  .Barra {
    zoom: 70%;
    top: 44px;
    background-color: rgba(4, 4, 14, 0.675);
    backdrop-filter: blur(8px);
    width: 1800px;
  }


  .Contenido .c { display: block; }
  .Contenido .texto { text-align: center; }
  .Contenido img { padding: 0; }
  .SobreNosotros { padding: 20px; padding-top: 120px; }
  
  .SobreNosotros .perfil {
    display: block;
  }
  .SobreNosotros .perfil img {
    height: 300px;

  }

}

